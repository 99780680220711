<template>
  <div class="d-flex flex-column flex-root">
    <v-overlay>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <v-row justify="center" align="center">
      <ConfirmationDialog
        v-model="openConfirmationDialog"
        :title="this.dialogTitle"
        :content="this.dialogMsg"
        :showProceedButton="true"
        :showBackButton="false"
        :delayOnProceed="false"
        proceedButton="Ok"
        @onProceed="onProceed"
      ></ConfirmationDialog>
    </v-row>
  </div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

import {
  REDIRECTOR,
} from "@/core/services/store/redirector.module";

import JwtService from "@/core/services/jwt.service";
import config from "@/core/services/store/config.module";
import Loader from "@/view/content/Loader.vue";

export default {
  name: "Redirector",

  components: {
    Loader,
  },

  data() {
    return {
      status: "",
      dialogMsg: "",
      dialogTitle: "",
      next: "/dashboard", // default next route
      openConfirmationDialog: false,
    };
  },

  computed: {
    ...mapGetters([
      "layoutConfig",
    ]),

    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED")
    // if (JwtService.getToken()) {
      switch (this.$route.query.act) {
        case "verify_email":
          //nfh-console.log('REDIRECTOR Router.Replace: VERIFYEMAIL', this.$route.query)
          this.$router.replace({
            name: "VerifyEmail",
            query: this.$route.query,
          });
          break;

        case "accept_application":
          this.$router.replace({
            name: "Dashboard",
            query: this.$route.query,
          });
          break;

        case "view_application":
          this.$router.replace({
            name: "Dashboard",
            query: this.$route.query,
          });
          break;

        case "help_application":
          this.$router.replace({
            name: "Dashboard",
            query: this.$route.query,
          });
          break;

        case "accept_form":
          this.$router.replace({
            name: "Dashboard",
            query: this.$route.query,
          });
          break;

        case "reset_password":
          this.$router.replace({
            name: "ResetPassword",
            query: this.$route.query,
          });
          break;

        default:
          // xxconsole.log('REDIRECTOR Router.Replace: DEFAULT')
          await this.$store
            .dispatch(REDIRECTOR, this.$route.query)
            .then((response) => {
              if (response?.data?.success == true) {
                // xxconsole.log('REDIRECTOR Router.Replace: DEFAULT -> SUCCESS ->', this.$route.query.next, this.$route.query)
                this.$router.replace({
                  path: this.$route.query.next,
                  query: this.$route.query,
                });
              } else {
                // xxconsole.log('REDIRECTOR Router.Replace: DEFAULT -> NOT-SUCCESS ->', this.$route.query.next, this.$route.query)
                this.next = this.$route.query.next;
                this.dialogTitle = "Operasi Tidak Dapat Diteruskan";
                this.dialogMsg = response.data.message;
                this.openConfirmationDialog = true;
              }
            })
            .catch((err) => {
              this.next = this.$route.query.next;
              this.dialogTitle = "Berlaku ralat yang tidak dijangka ketika proses peralihan pautan.";
              this.dialogMsg = err.toString();
              this.openConfirmationDialog = true;
            })
            .finally(() => {
              this.next = this.$route.query.next;
            });
      }
    // }
    // else {
    //   // const queryData = this.$route.query;
    //   // queryData.next = "";
    //   // queryData.appid = (process.env.NODE_ENV === "production") ? config.state.appid_prod : config.state.appid_dev;
    //   // this.$router.replace({ name: "Login", query: queryData });
    //   try {
    //       console.log('REDIRECTOR Router.Replace: LOGIN', this.$route.query)
    //     this.$router.replace({
    //       name: "Login",
    //       query: this.$route.query,
    //     });
    //   } catch (err) {
    //     console.log('REDIRECTOR Router.Replace: LOGIN -> ERROR', err)
    //     if (!(err instanceof NavigationDuplicated)) {
    //       throw err;
    //     }
    //   }
    // }
    //nfh-console.log("OUT:MOUNTED")
  },

  methods: {
    onProceed() {
      this.openConfirmationDialog = false;
      this.$router.replace({
        path: this.next,
        query: this.$route.query,
      });
    },
  },

};
</script>
<style scoped>
.body {
  /* background-color: #f3f6f9; */
  background-image: url("/media/bg/bg-login.png");
  background-size: cover;
  background-color: #f5f5f9;
}
</style>