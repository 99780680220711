var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column flex-root" },
    [
      _c(
        "v-overlay",
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", size: "64" },
          }),
        ],
        1
      ),
      _vm.loaderEnabled
        ? _c("Loader", { attrs: { logo: _vm.loaderLogo } })
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c("ConfirmationDialog", {
            attrs: {
              title: this.dialogTitle,
              content: this.dialogMsg,
              showProceedButton: true,
              showBackButton: false,
              delayOnProceed: false,
              proceedButton: "Ok",
            },
            on: { onProceed: _vm.onProceed },
            model: {
              value: _vm.openConfirmationDialog,
              callback: function ($$v) {
                _vm.openConfirmationDialog = $$v
              },
              expression: "openConfirmationDialog",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }